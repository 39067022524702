body {
  background-color: #252b42;
  margin: 0 auto;
}
/* Start Header */

header.header__wrapper {
  width: 100%;
  height: 91px;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  padding: 0 105px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat;
  color: #fff;
}
header .header__logo {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1px;
  /* color: #FFFFFF; */
}

header .header__menu-items__wrapper {
  margin-left: 90px;
  margin-right: 20px;
}
header nav ul.header__menu-items {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
}

header .header__button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

header .header__button-wrapper a.header__button-login {
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #23a6f0;
}

header .header__button-wrapper button.header__button-member {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #ffffff;
  background-color: #23a6f0;
  border: none;
  border-radius: 5px;
  height: 52px;
  width: 100%;
  padding: 0px 25px;
}
header.mobile-header {
  display: none;
}

@media only screen and (min-width: 769px) and (max-width: 930px) {
  header.header__wrapper {
    padding: 0 12px;
  }
  header .header__menu-items__wrapper {
    margin-left: 45px;
    margin-right: 20px;
  }
  
  
} 
@media only screen and (max-width: 768px) {
  header {
    background-color: rgba(37, 43, 66, 0.9);
  }
  header.header__wrapper {
    display: none;
  }
}
/* End Header */

/*  Start Mobile Header */
@media only screen and (max-width: 768px) {
  header.mobile-header {
    width: 100%;
    height: auto;
    display: block;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    padding: 0 30px;
    padding-bottom: 12px;
    font-family: Montserrat;
    font-size: 24px;
    color: #fff;
  }
  .flexed {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-top: 55px;
  }
  .mobile-header__logo {
    font-weight: 700;
  }
  .mobile-header__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 35px;
  }
  .mobile-header__icon-wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  nav.mobile-header__menu-items__wrapper {
    display: none;
  }
  ul.mobile-header__menu-items {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    gap: 20px;
  }
}
/*  End Mobile Header */

/* Hero Start */
section.hero__wrapper {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Montserrat;
}
section div.hero__text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 780px;
  gap: 40px;
}
section div.hero__text-wrapper h1.hero__heading {
  font-size: 58px;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: 0.2px;
}

section div.hero__text-wrapper p.hero__text {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.2px;
  max-width: 75%;
}

section div.hero__text-wrapper div.hero__button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
section div.hero__text-wrapper div.hero__button-wrapper button {
  border-radius: 37px;
  padding: 15px 36px;
  height: 52px;
  width: auto;
  color: #fff;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
}

section
  div.hero__text-wrapper
  div.hero__button-wrapper
  button.hero__button-quote {
  background: #23a6f0;
  border: none;
}
section
  div.hero__text-wrapper
  div.hero__button-wrapper
  button.hero__button-quote:hover {
  background: rgba(35, 166, 240, 0.5);
}

section
  div.hero__text-wrapper
  div.hero__button-wrapper
  button.hero__button-learn {
  border: 1px solid #fff;
}

section
  div.hero__text-wrapper
  div.hero__button-wrapper
  button.hero__button-learn:hover {
  background-color: rgb(180, 180, 180);
  color: black;
}
@media only screen and (max-width: 768px) {
  section.hero__wrapper {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Montserrat;
  }
  section div.hero__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 780px;
    gap: 40px;
  }
  section div.hero__text-wrapper h1.hero__heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.2px;
  }

  section div.hero__text-wrapper p.hero__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.2px;
    max-width: 75%;
  }

  section div.hero__text-wrapper div.hero__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  section div.hero__text-wrapper div.hero__button-wrapper button {
    width: 215px;
  }
}
/* Hero End */

/* Services Cards Start */
section.services__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #fff;
  width: 100%;
  margin-bottom: 150px;
}

section.services__wrapper .services__heading-wrapper {
  text-align: center;
  margin-bottom: 55px;
  max-width: 600px;
}
section.services__wrapper .services__heading-wrapper h1.services__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}

section.services__wrapper .services__heading-wrapper p.services__subHeading {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
}
section.services__wrapper div.services__cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

section.services__wrapper
  div.services__cards-wrapper
  div.services__card-wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 25px;
  max-width: 325px;
}
section.services__wrapper
  div.services__cards-wrapper
  div.services__card-wrapper
  img {
  height: 35px;
  width: 35px;
  object-fit: contain;
  object-position: center;
}

section.services__wrapper
  div.services__cards-wrapper
  div.services__card-wrapper
  div.services__card-text__wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
section.services__wrapper
  div.services__cards-wrapper
  div.services__card-wrapper
  div.services__card-text__wrapper
  h1.services__card-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
section.services__wrapper
  div.services__cards-wrapper
  div.services__card-wrapper
  div.services__card-text__wrapper
  p.services__card-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Services Cards Ends*/

/* Client Start */
section.client__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #fff;
  width: 100%;
}
section.client__wrapper .client__heading-wrapper {
  text-align: center;
  margin-bottom: 55px;
}
section.client__wrapper h2.client__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}

section.client__wrapper p.client__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
}

section.client__wrapper .client__cards-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-bottom: 175px;
}

section.client__wrapper .client__cards-wrapper .client__cards {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  color: black;
}
section.client__wrapper
  .client__cards-wrapper
  .client__cards
  .client__card-text__wrapper {
  padding: 16px 25px;
}

section.client__wrapper
  .client__cards-wrapper
  .client__cards
  .client__card-text__wrapper
  h3.client__name {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1px;
}
section.client__wrapper
  .client__cards-wrapper
  .client__cards
  .client__card-text__wrapper
  p.client__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #737373;
}

section.client__wrapper
  .client__cards-wrapper
  .client__cards
  .client__card-text__wrapper
  div.client__rating {
  margin: 8px 0;
}
section.client__wrapper
  .client__cards-wrapper
  .client__cards
  .client__card-text__wrapper
  div.client__rating
  i {
  margin: 12px 0;
  color: #f3cd03;
}
section.client__wrapper
  .client__cards-wrapper
  .client__cards
  .client__card-text__wrapper
  p.client__feedback {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #737373;
}
@media only screen and (max-width: 768px) {
  section.client__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    color: #fff;
    width: 100%;
  }
  section.client__wrapper .client__heading-wrapper {
    text-align: center;
    margin-bottom: 55px;
  }
  section.client__wrapper h2.client__heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.2px;
    margin-bottom: 12px;
  }

  section.client__wrapper p.client__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    max-width: 60%;
    text-align: center;
    margin: 0 auto;
  }

  section.client__wrapper .client__cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 175px;
    width: 328px;
  }

  section.client__wrapper .client__cards-wrapper .client__cards {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: black;
  }
}
/* Client End */

/*About Us Start */
section.about__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #fff;
  width: 100%;
}

section.about__wrapper div.about__heading-wrapper {
  text-align: center;
  margin-bottom: 55px;
  width: 65%;
}

section.about__wrapper div.about__heading-wrapper h1.about__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}

section.about__wrapper div.about__heading-wrapper p.about__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
}

section.about__wrapper div.about__content-wrapper {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-bottom: 175px;
}

section.about__wrapper div.about__video-wrapper {
  width: 60%;
  position: relative;
}
section.about__wrapper div.about__video-wrapper video {
  height: 100%;
  width: 100%;
}
section.about__wrapper div.about__video-wrapper i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #24a6f0;
  height: 70px;
  width: 70px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
section.about__wrapper div.about__video-wrapper i.fa-solid.fa-pause {
  display: none;
}

section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
}
section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-heading-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-heading-wrapper
  h2.about__video-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1px;
}
section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-heading-wrapper
  p.about__video-heading-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
}
section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-quote__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
}
section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-quote__wrapper
  img {
  height: 35px;
  width: 35px;
}

section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-quote__wrapper
  div.about__video-quote-text__wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
section.about__wrapper
  div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-quote__wrapper
  div.about__video-quote-text__wrapper
  h3.about__video-quote-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
div.about__content-wrapper
  div.about__video-text__wrapper
  div.about__video-quote__wrapper
  div.about__video-quote-text__wrapper
  p.about__video-quote-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  section.about__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    color: #fff;
    width: 100%;
  }

  section.about__wrapper div.about__heading-wrapper {
    text-align: center;
    margin-bottom: 55px;
    width: 100%;
  }

  section.about__wrapper div.about__heading-wrapper h1.about__heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.2px;
    margin-bottom: 12px;
  }

  section.about__wrapper div.about__heading-wrapper p.about__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    max-width: 60%;
    text-align: center;
    margin: 0 auto;
  }

  section.about__wrapper div.about__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    margin-bottom: 175px;
  }

  section.about__wrapper div.about__video-wrapper {
    width: 100%;
    position: relative;
  }

  section.about__wrapper div.about__video-wrapper video {
    height: 240px;
    width: 100%;
  }
  video[poster] {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  section.about__wrapper div.about__video-wrapper i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #24a6f0;
    height: 70px;
    width: 70px;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  section.about__wrapper
    div.about__content-wrapper
    div.about__video-text__wrapper {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
  }
  section.about__wrapper
    div.about__content-wrapper
    div.about__video-text__wrapper
    div.about__video-heading-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  section.about__wrapper
    div.about__content-wrapper
    div.about__video-text__wrapper
    div.about__video-heading-wrapper
    h2.about__video-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1px;
  }
  section.about__wrapper
    div.about__content-wrapper
    div.about__video-text__wrapper
    div.about__video-heading-wrapper
    p.about__video-heading-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  section.about__wrapper
    div.about__content-wrapper
    div.about__video-text__wrapper
    div.about__video-quote__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
  }
}
/* About Us End */

/* Prices Start */
section.pricing__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #fff;
  width: 100%;
  margin-bottom: 150px;
}
section.pricing__wrapper .pricing__heading-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 55px;
}
section.pricing__wrapper h2.pricing__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}

section.pricing__wrapper p.pricing__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
}

section.pricing__wrapper .pricing__cards-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}
section.pricing__wrapper .pricing__cards-wrapper .pricing__card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;
  color: #000;
  background-color: #fff;
  height: 670px;
  border-radius: 10px;
  padding: 50px 40px;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  span.pricing__card-badge {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  top: -45px;
  right: 15px;
  background: #e77c40;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1px;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  h3.pricing__card-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1px;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  p.pricing__card-description {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #737373;
  max-width: 160px;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  h4.pricing__card-price {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0.2px;
  color: #23a6f0;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  p.pricing__card-price__tag {
  color: #23a6f0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
}
section.pricing__wrapper .pricing__cards-wrapper .pricing__card-wrapper button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
  background: #23a6f0;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  ul.pricing__features-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  ul.pricing__features-wrapper
  li.pricing__features::before {
  content: "✔";
  position: relative;
  color: #fff;
  background-color: #2dc071;
  border-radius: 50%;
  font-size: 15px;
  margin-right: 5px;
  padding: 7px 10px;
}
section.pricing__wrapper
  .pricing__cards-wrapper
  .pricing__card-wrapper
  ul.pricing__features-wrapper
  li.pricing__features {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  section.pricing__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    color: #fff;
    width: 100%;
    margin-bottom: 150px;
  }
  section.pricing__wrapper .pricing__heading-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 55px;
  }
  section.pricing__wrapper h2.pricing__heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.2px;
    margin-bottom: 12px;
  }

  section.pricing__wrapper p.pricing__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    max-width: 60%;
    text-align: center;
    margin: 0 auto;
  }

  section.pricing__wrapper .pricing__cards-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 55px;
    align-items: center;
    justify-content: space-between;
  }
  section.pricing__wrapper .pricing__cards-wrapper .pricing__card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    color: #000;
    background-color: #fff;
    height: 670px;
    border-radius: 10px;
    padding: 50px 40px;
  }
}
/* Prices End */

/* Contact us start */
section.contact__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #fff;
  width: 100%;
  margin-bottom: 162px;
}

section.contact__wrapper div.contact__header-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 80%;
  margin-bottom: 135px;
}

section.contact__wrapper div.contact__header-wrapper h1.contact__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}
section.contact__wrapper div.contact__header-wrapper p.contact__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
}
section.contact__wrapper div.contact__cards-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
section.contact__wrapper div.contact__cards-wrapper div.contact__card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 343px;
  width: 100%;
  background: #fff;
  color: #000;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper.middle {
  height: 395px;
  position: relative;
  top: -25px;
  background: #23a6f0;
  color: #fff;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper.middle
  i.contact__card-image {
  color: #fff;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper
  i.contact__card-image {
  font-size: 72px;
  margin-bottom: 15px;
  color: #23a6f0;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper
  p.contact__card-email {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}

section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper
  h2.contact__card-support {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-bottom: 15px;
}

section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper
  button.contact__card-button {
  background: transparent;
  transition: all ease-in-out 0.3ms;
  height: 54px;
  width: 50%;
  border-radius: 37px;
  border: 1px #23a6f0 solid;
  color: #23a6f0;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper
  button.contact__card-button:hover {
  background-color: rgba(35, 166, 240, 0.8);
  color: #fff;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper.middle
  button.contact__card-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #23a6f0;
}
section.contact__wrapper
  div.contact__cards-wrapper
  div.contact__card-wrapper.middle
  button.contact__card-button {
  border: 1px #fff solid;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  section.contact__wrapper div.contact__header-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
  }
  section.contact__wrapper div.contact__cards-wrapper {
    display: flex;
    flex-direction: column;
    width: 67%;
    gap: 60px;
  }
  section.contact__wrapper
    div.contact__cards-wrapper
    div.contact__card-wrapper {
    padding: 0 30px;
  }
  section.contact__wrapper
    div.contact__cards-wrapper
    div.contact__card-wrapper
    button.contact__card-button {
    width: 100%;
    border-radius: 5px;
  }
  section.contact__wrapper
    div.contact__cards-wrapper
    div.contact__card-wrapper.middle {
    height: 343px;
    position: relative;
    top: 0px;
    background: #23a6f0;
    color: #fff;
  }
}
/* Contact us end */

/* Start Footer */
footer {
  color: #fff;
  font-family: Montserrat;
}
.footer__heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer__heading-brand {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.footer__heading-icons__wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.footer__heading-icon i {
  color: #23a6f0;
  font-size: 24px;
}
hr.footer__line {
  border: 1px solid #e6e6e6;
  margin: 53px 0;
}
.footer__menu__wrapper {
  display: flex;
  flex-direction: row;
  gap: 35px;
}
.footer__menu__wrapper .footer__menu-items__wrapper h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  white-space: nowrap;
}
.footer__menu__wrapper .footer__menu-items__wrapper ul.footer__menu-lists {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer__menu__wrapper .footer__menu-lists .footer__menu-lists__items {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
}
.footer__menu__wrapper .footer__menu-items__wrapper form.footer__menu-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 62px;
}
.footer__menu__wrapper
  .footer__menu-items__wrapper
  form.footer__menu-form
  input {
  box-sizing: content-box;
  height: auto;
  width: 75%;
  color: #000;
  border: 1px solid #e6e6e6;
  border-radius: 5px 0 0 5px;
}
.footer__menu__wrapper
  .footer__menu-items__wrapper
  form.footer__menu-form
  button {
  height: 100%;
  width: 25%;
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.2px;
  align-items: center;
  font-weight: 400;
  background-color: #23a6f0;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0px 5px 5px 0px;
}
.footer__menu__wrapper .footer__menu-items__wrapper p {
  margin-top: 1px;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
}
footer p.disclaimer {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: center;
  margin-top: 24px;
}

@media only screen and (max-width: 780px) {
  .footer__heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: space-between;
  }
  hr.footer__line {
    border: 1px solid #e6e6e6;
    margin: 53px 0;
    width: 100%;
  }
  .footer__menu__wrapper {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .footer__menu__wrapper .footer__menu-items__wrapper h6 {
    font-size: 25px;
  }
}
/* End Footer */
